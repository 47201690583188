import { AxiosWrapper } from "~/utils/axios-wrapper";
import {
  createAuthHeader,
  createReqUpdateAuth,
} from "~/utils/http/axios/interceptors";
import type {
  UploadFileViaAdminRequest,
  UploadFileViaAdminResponse,
} from "~/composables/api/dto/upload_file";
import type {
  DeleteFileViaAdminRequest,
  DeleteFileViaAdminResponse,
} from "~/composables/api/dto/delete_file";
import type {
  GetFileByIdViaAdminRequest,
  GetFileByIdViaAdminResponse,
} from "~/composables/api/dto/get_file_by_id";

class FileAPI extends AxiosWrapper {
  constructor(apiBaseUrl: string) {
    super({
      apiEndpoint: apiBaseUrl,
      config: {
        withCredentials: true,
      },
      interceptors: {
        request: {
          fulfilled: [createAuthHeader],
        },
        response: {
          rejected: [createReqUpdateAuth],
        },
      },
    });
  }

  uploadFileViaAdmin(
    data: UploadFileViaAdminRequest,
  ): UploadFileViaAdminResponse {
    const formData = new FormData();

    for (const key in data) {
      if (!data[key]) continue;

      formData.append(key, data[key]);
    }

    return this.post(`${this.apiEndpoint}/api/v1/admin/file`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  deleteFileViaAdmin(
    data: DeleteFileViaAdminRequest,
  ): DeleteFileViaAdminResponse {
    return this.delete(`${this.apiEndpoint}/api/v1/admin/file/${data.fileId}`);
  }

  getFileByIdViaAdmin(
    data: GetFileByIdViaAdminRequest,
  ): GetFileByIdViaAdminResponse {
    return this.get(`${this.apiEndpoint}/api/v1/admin/file/${data.fileId}`);
  }
}

export const useFileApi = createSharedComposable(() => {
  const { NUXT_API_BASE_URL } = useRuntimeConfig().public;

  return new FileAPI(NUXT_API_BASE_URL);
});
